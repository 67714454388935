<template>
   <div class="px-8 py-8 mx-auto bg-white rounded-lg shadow mb-10" :class="form.category.value != '' ? '' : 'mb-44'">
      <div class="">
         <section v-show="step == 1" ref="form-1" class="animate__animated animate__fadeIn">
            <ValidationObserver v-slot="{ handleSubmit }" ref="form-1">
               <form class="mt-2 grid grid-cols-2 gap-5 mb-4">
                  <div class="relative">
                     <label class="text-gray-700 font-semibold" for="password">Currency</label>
                     <og-custom-select-with-image :data="currencies" v-model="form.currency" v-on:valueSelect="onCurrencySelect"></og-custom-select-with-image>
                  </div>
                  <div class="relative">
                     <label class="text-gray-700 font-semibold">Category</label>
                     <og-custom-select :data="categories" :showImage="false" v-model="form.category" v-on:valueSelect="onServiceSelect"></og-custom-select>
                  </div>
                  <div class="animate__animated animate__fadeIn col-span-2 grid grid-cols-2 gap-5" v-if="form.category.value != ''">
                     <div class="col-span-2">
                        <h3 class="text-xl text-indigo-900 font-semibold border-b border-gray-100 py-3">{{ form.category.name }} information</h3>
                     </div>
                     <ValidationProvider tag="div" class="col-span-2" :name="form.category.name" rules="required" v-slot="{ errors }">
                        <label class="text-gray-700 font-semibold" for="passwordConfirmation"><span v-if="form.category.name != 'Services'">Name of {{ form.category.name }}</span><span v-else>Type of service</span></label>
                        <input type="text" placeholder="Name of product you wish to buy" v-model="form.service_name"
                           class="block w-full px-4 py-3 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md focus:border-indigo-600 transition ease-in-out duration-150 focus:outline-none"
                           :class="errors.length > 0 ? 'border-red-600 focus:border-red-600' : 'focus:border-indigo-600 '">
                        <span class="text-sm  text-red-600 font-semibold">{{ errors[0] }}</span>
                     </ValidationProvider>
                     <ValidationProvider tag="div" name="item price" rules="required|double" v-slot="{ errors }">
                        <label class="text-gray-700 font-semibold" for="password">Item Price</label>
                        <div class="flex items-center block w-full pr-4 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md 
                           focus:border-indigo-600 transition ease-in-out duration-150 focus:outline-none">
                           <span class="flex items-center border-0 rounded-md px-4 m-0.5 w-12 py-2.5 bg-gray-100 font-bold">
                              <template v-if="form.currency.name == 'USD'">
                                 <span class="">&#36;</span>
                              </template>
                              <template v-else-if="form.currency.name == 'NGN'">
                                 <span class="">&#8358;</span>
                              </template>
                              <template v-else-if="form.currency.name == 'CNY'">
                                 <span class="">&#165;</span>
                              </template>
                              <template v-else>
                                 <span class="">&#8383;</span>
                              </template>
                           </span>
                           <input v-model="form.price"
                              class="w-full text-gray-700 bg-white pl-3 focus:outline-none focus:border-indigo-600 transition ease-in-out duration-150 focus:outline-none"
                              :class="errors.length > 0 ? 'border-red-600 focus:border-red-600' : 'focus:border-indigo-600 '">
                        </div>
                        <span class="text-sm  text-red-600 font-semibold">{{ errors[0] }}</span>
                     </ValidationProvider>
                     <ValidationProvider tag="div" name="transaction duration" rules="required" v-slot="{ errors }">
                        <label class="text-gray-700 font-semibold">Estimated transaction end date</label>
                        <div class="mt-2">
                           <MazPicker v-model="form.duration" clearable 
                              placeholder="Select date" formatted="ll" :minDate="today" class="z-20"
                              :class="errors.length > 0 ? 'border-red-600 focus:border-red-600' : 'focus:border-indigo-600 '"
                              no-time/>
                        </div>
                        <span class="text-sm  text-red-600 font-semibold">{{ errors[0] }}</span>
                     </ValidationProvider>
                     <ValidationProvider tag="div" class="col-span-2" name="description" rules="required" v-slot="{ errors }">
                        <label class="text-gray-700 font-semibold" for="passwordConfirmation">Product description/Extra information</label>
                        <textarea type="text" rows="4" placeholder="Enter short product description/details" v-model="form.description"
                        class="block w-full px-4 py-3 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md focus:border-indigo-600 transition ease-in-out duration-150 focus:outline-none"
                        :class="errors.length > 0 ? 'border-red-600 focus:border-red-600' : 'focus:border-indigo-600 '"></textarea>
                        <span class="text-sm  text-red-600 font-semibold">{{ errors[0] }}</span>
                     </ValidationProvider>
                     <template v-if="form.category.name == 'Services'">
                        <div class="ml-10 col-span-2">
                           <hr class="border-t border-gray-100 my-5">
                           <div class="flex justify-end">
                              <span @click="addMilestone()" class="bg-indigo-900 rounded shadow-sm ring-opacity-50 text-white hover:bg-indigo-800 transition text-xs py-3 px-3 cursor-pointer">Add Milestone (Optional)</span>
                           </div>
                           <div v-for="(m, index) in form.milestone" :key="index">
                              <div class="my-5 grid grid-cols-2 gap-5 pb-5 bg-gray-50 px-5 rounded-md">
                                 <div class="py-5  col-span-2 text-base font-semibold border-b border-gray-100 flex justify-between">
                                    <div class="text-indigo-900">Milestone {{ index + 1 }}</div>
                                    <div class="self-center bg-gray-200 rounded-full p-1 hover:text-indigo-900 cursor-pointer" @click="removeMilestone(m)">
                                       <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                                       </svg>
                                    </div>
                                 </div>
                                 <ValidationProvider tag="div" class="col-span-2" :name="`milestone ${ m }`" v-slot="{ errors }">
                                    <label class="text-gray-700 font-semibold">Title</label>
                                    <input type="text" placeholder="" v-model="form.milestone[index].title"
                                       class="block w-full px-4 py-3 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md focus:border-indigo-600 transition ease-in-out duration-150 focus:outline-none"
                                       :class="errors.length > 0 ? 'border-red-600 focus:border-red-600' : 'focus:border-indigo-600 '">
                                    <span class="text-sm  text-red-600 font-semibold">{{ errors[0] }}</span>
                                 </ValidationProvider>
                                 <ValidationProvider tag="div" class="col-span-2" name="milestone description" rules="" v-slot="{ errors }">
                                    <label class="text-gray-700 font-semibold">Description</label>
                                    <textarea type="text" rows="4" placeholder="Enter short product description/details" v-model="form.milestone[index].description"
                                    class="block w-full px-4 py-3 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md focus:border-indigo-600 transition ease-in-out duration-150 focus:outline-none"
                                    :class="errors.length > 0 ? 'border-red-600 focus:border-red-600' : 'focus:border-indigo-600 '"></textarea>
                                    <span class="text-sm  text-red-600 font-semibold">{{ errors[0] }}</span>
                                 </ValidationProvider>
                                 <ValidationProvider tag="div" name="amount to be released" rules="double" v-slot="{ errors }">
                                    <label class="text-gray-700 font-semibold" for="password">Amount to be released</label>
                                    <div class="flex items-center block w-full pr-4 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md 
                                       focus:border-indigo-600 transition ease-in-out duration-150 focus:outline-none">
                                       <span class="flex items-center border-0 rounded-md px-4 m-0.5 w-12 py-2.5 bg-gray-100 font-bold">
                                          <template v-if="form.currency.name == 'USD'">
                                             <span class="">&#36;</span>
                                          </template>
                                          <template v-else-if="form.currency.name == 'NGN'">
                                             <span class="">&#8358;</span>
                                          </template>
                                          <template v-else-if="form.currency.name == 'CNY'">
                                             <span class="">&#165;</span>
                                          </template>
                                          <template v-else>
                                             <span class="">&#8383;</span>
                                          </template>
                                       </span>
                                       <input v-model="form.milestone[index].amount_to_release"
                                          class="w-full text-gray-700 bg-white pl-3 focus:outline-none focus:border-indigo-600 transition ease-in-out duration-150 focus:outline-none"
                                          :class="errors.length > 0 ? 'border-red-600 focus:border-red-600' : 'focus:border-indigo-600 '">
                                    </div>
                                    <span class="text-sm  text-red-600 font-semibold">{{ errors[0] }}</span>
                                 </ValidationProvider>
                                 <ValidationProvider tag="div" name="milestone due date" rules="" v-slot="{ errors }">
                                    <label class="text-gray-700 font-semibold">Milestone due date</label>
                                    <div class="mt-2">
                                       <MazPicker v-model="form.milestone[index].due_date" clearable 
                                          placeholder="Select date" formatted="ll" :minDate="today" class="z-20"
                                          :class="errors.length > 0 ? 'border-red-600 focus:border-red-600' : 'focus:border-indigo-600 '"
                                          no-time/>
                                    </div>
                                    <span class="text-sm  text-red-600 font-semibold">{{ errors[0] }}</span>
                                 </ValidationProvider>
                              </div>
                              <hr class="border-t border-dashed my-5">
                           </div>
                        </div>
                     </template>
                     <div class="ml-10 col-span-2 pt-5">
                        <div class="text-indigo-900 text-lg font-semibold border-b border-gray-100 pb-8 ">Upload Images/Videos (<span class="text-gray-400 text-sm">Optional</span>)</div>
                        <div class="">
                           <div class="mt-5">
                              Upload Video (<span class="text-gray-400 text-sm">Max. size 5mb</span>)
                           </div>
                           <div class="flex w-full border-gray-300 justify-center border cursor-pointer rounded-md mt-2">
                              <div id="multi-upload-button" 
                                 class="items-center w-44 text-center px-3 py-3 border-r border-gray-300 bg-gray-200 rounded-r-none rounded-md 
                                    font-semibold cursor-pointer text-sm text-gray-700 hover:bg-gray-300 focus:outline-none transition">
                                 Click to browse
                              </div>
                              <div class="flex w-full px-3 items-center justify-between">
                                 <span id="multi-upload-text" class="p-2"></span>
                                 <button id="multi-upload-delete" class="" onclick="removeMultiUpload()">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="fill-current text-red-700 w-3 h-3"
                                       viewBox="0 0 320 512">
                                       <path d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"/>
                                    </svg>
                                 </button>
                              </div>
                           </div>
                           <input type="file" id="multi-upload-input" class="hidden" multiple/>
                        </div>
                        <div class="mt-10">
                           <div class="flex justify-between w-full">
                              <span class="justify-start">Upload Images (<span class="text-gray-400 text-sm">Max. size 1mb</span>)</span>
                              <span @click="imageFieldCount++" class="justify-end bg-green-600 rounded shadow-sm ring-opacity-50 text-white hover:bg-green-700 transition text-xs py-1 px-3 cursor-pointer">+ Add field</span>
                           </div>
                           <div class="mt-2 mb-4">
                              <div v-for="n in imageFieldCount" :key="n">
                                 <template v-if="n <= 5">
                                    <div class="flex w-full border-gray-300 justify-center border cursor-pointer rounded-md mt-2 mb-4">
                                       <div id="multi-upload-button" 
                                          class="items-center w-40 text-center px-4 py-3 border-r border-gray-300 bg-gray-200 rounded-r-none rounded-md 
                                             font-semibold cursor-pointer text-sm text-gray-700 hover:bg-gray-300 focus:outline-none transition">
                                          Click to browse
                                       </div>
                                       <div class="flex w-full px-3 items-center justify-between">
                                          <span id="multi-upload-text" class="p-2"></span>
                                          <button id="multi-upload-delete" class="" @clcik.prevent="removeMultiUpload()">
                                             <svg xmlns="http://www.w3.org/2000/svg" class="fill-current text-red-700 w-3 h-3"
                                                viewBox="0 0 320 512">
                                                <path d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"/>
                                             </svg>
                                          </button>
                                       </div>
                                    </div>
                                    <input type="file" id="multi-upload-input" class="hidden" multiple/>
                                 </template>
                              </div>
                           </div>
                        </div>  
                     </div>                      
                  </div>
                  <div class="col-span-2 mt-10">
                     <div class="flex justify-end">
                        <button @click.prevent="handleSubmit(checkMilestone)" :class="form.category.value == '' ? 'bg-indigo-300 hover:bg-indigo-400 cursor-not-allowed' : 'bg-indigo-900 hover:bg-indigo-900'"
                           class="px-10 font-medium py-3 leading-5 text-white transition-colors duration-200 transform rounded-md focus:outline-none">Next</button>
                     </div>
                  </div>
               </form>
            </ValidationObserver>
         </section>
         <section v-show="step == 2" ref="form-2" class="animate__animated animate__fadeIn">
            <ValidationObserver v-slot="{ handleSubmit }" ref="form-2">
               <form class="mt-2 grid grid-cols-2 gap-5 mb-4">
                  <div class="text-indigo-900 text-lg font-semibold">Your information</div>
                  <template v-if="isProfile == 'personal'">
                     <ValidationProvider tag="div" name="fullname" rules="required" class="col-span-2" v-slot="{ errors }">
                        <label class="text-gray-700 font-semibold">Full Name</label>
                        <input type="text" v-model="form.seller_name" placeholder="Enter your full name"
                           class="w-full px-4 py-3 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md 
                              focus:border-indigo-600 transition ease-in-out duration-150 focus:outline-none"
                           :class="errors.length > 0 ? 'border-red-600 focus:border-red-600' : 'focus:border-indigo-600 '">
                        <span class="text-sm  text-red-600 font-semibold">{{ errors[0] }}</span>
                     </ValidationProvider>
                     <div class="col-span-2 text-center">
                        <div class="self-center text-indigo-900 font-medium underline text-sm cursor-pointer" @click="isProfile = 'business'; form.seller_name = ''; form.seller_phone = ''; form.seller_email = ''">
                           Use business name instead (Business must have registered bank account)
                        </div>
                     </div>
                     <ValidationProvider tag="div" name="phone number" rules="required" class="col-span-2" v-slot="{ errors }">
                        <label class="text-gray-700 font-semibold">Your phone number <span class="text-red-600">*</span></label>
                        <MazPhoneNumberInput v-model="form.seller_phone" default-country-code="NG" class="mt-2" :class="errors.length > 0 ? 'border-red-600 focus:border-red-600' : 'focus:border-indigo-600 '"/>
                        <span class="text-sm  text-red-600 font-semibold">{{ errors[0] }}</span>
                     </ValidationProvider>
                     <ValidationProvider tag="div" name="email" rules="required|email" class="col-span-2" v-slot="{ errors }">
                           <label class="text-gray-700 font-semibold">Your email address <span class="text-red-600">*</span></label>
                           <input type="email" v-model="form.seller_email" placeholder="yourname@email.com" :class="errors.length > 0 ? 'border-red-600 focus:border-red-600' : 'focus:border-indigo-600 '"
                              class="w-full px-4 py-3 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md 
                                 focus:border-indigo-600 transition ease-in-out duration-150 focus:outline-none">
                        <span class="text-sm  text-red-600 font-semibold">{{ errors[0] }}</span>
                     </ValidationProvider>
                  </template>
                  <template v-else>
                     <ValidationProvider tag="div" name="business name" rules="required" class="col-span-2" v-slot="{ errors }">
                        <label class="text-gray-700 font-semibold">Business Name</label>
                        <input type="text" v-model="form.seller_name" placeholder="Enter your full name"
                           class="w-full px-4 py-3 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md 
                              focus:border-indigo-600 transition ease-in-out duration-150 focus:outline-none"
                           :class="errors.length > 0 ? 'border-red-600 focus:border-red-600' : 'focus:border-indigo-600 '">
                        <span class="text-sm  text-red-600 font-semibold">{{ errors[0] }}</span>
                     </ValidationProvider>
                     <div class="col-span-2 text-center">
                        <div class="self-center text-indigo-900 font-medium underline text-sm cursor-pointer" @click="isProfile = 'personal'; form.seller_name = ''; form.seller_phone = ''; form.seller_email = ''">
                           Use personal details for this transaction
                        </div>
                     </div>
                     <ValidationProvider tag="div" name="business phone number" rules="required" class="col-span-2" v-slot="{ errors }">
                        <label class="text-gray-700 font-semibold">Business Phone Number <span class="text-red-600">*</span></label>
                        <MazPhoneNumberInput v-model="form.seller_phone" default-country-code="NG" class="mt-2" :class="errors.length > 0 ? 'border-red-600 focus:border-red-600' : 'focus:border-indigo-600 '"/>
                        <span class="text-sm  text-red-600 font-semibold">{{ errors[0] }}</span>
                     </ValidationProvider>
                     <ValidationProvider tag="div" name="business email" rules="required|email" class="col-span-2" v-slot="{ errors }">
                           <label class="text-gray-700 font-semibold">Business Email Address <span class="text-red-600">*</span></label>
                           <input type="email" v-model="form.seller_email" placeholder="yourname@email.com" :class="errors.length > 0 ? 'border-red-600 focus:border-red-600' : 'focus:border-indigo-600 '"
                              class="w-full px-4 py-3 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md 
                                 focus:border-indigo-600 transition ease-in-out duration-150 focus:outline-none">
                        <span class="text-sm  text-red-600 font-semibold">{{ errors[0] }}</span>
                     </ValidationProvider>
                  </template>
                  <div class="text-indigo-900 text-lg font-semibold border-t border-gray-100 col-span-2 pt-5 mt-2">Buyer's information</div>
                  <ValidationProvider tag="div" name="buyer's name" rules="required" class="col-span-2" v-slot="{ errors }">
                     <label class="text-gray-700 font-semibold">Buyer's Name (Personal name/Business name)</label>
                     <input type="text" v-model="form.buyer_name" placeholder="Enter name of buyer"
                        class="w-full px-4 py-3 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md 
                           focus:border-indigo-600 transition ease-in-out duration-150 focus:outline-none"
                        :class="errors.length > 0 ? 'border-red-600 focus:border-red-600' : 'focus:border-indigo-600 '">
                     <span class="text-sm  text-red-600 font-semibold">{{ errors[0] }}</span>
                  </ValidationProvider>
                  <ValidationProvider tag="div" name="buyer's phone number" rules="required" class="col-span-2" v-slot="{ errors }">
                     <label class="text-gray-700 font-semibold">Phone number <span class="text-red-600">*</span></label>
                     <MazPhoneNumberInput v-model="form.buyer_phone" default-country-code="NG" class="mt-2" 
                        :class="errors.length > 0 ? 'border-red-600 focus:border-red-600' : 'focus:border-indigo-600 '"/>
                     <span class="text-sm  text-red-600 font-semibold">{{ errors[0] }}</span>
                  </ValidationProvider>
                  <ValidationProvider tag="div" name="buyer's email" rules="required|email" class="col-span-2" v-slot="{ errors }">
                     <label class="text-gray-700 font-semibold">Buyer's email address <span class="text-red-600">*</span></label>
                     <input type="email" v-model="form.buyer_email" placeholder="buyermail@email.com" :class="errors.length > 0 ? 'border-red-600 focus:border-red-600' : 'focus:border-indigo-600 '"
                        class="w-full px-4 py-3 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md 
                           focus:border-indigo-600 transition ease-in-out duration-150 focus:outline-none">
                     <span class="text-sm  text-red-600 font-semibold">{{ errors[0] }}</span>
                  </ValidationProvider>
                  <div class="col-span-2">
                     <div class="flex justify-end">
                        <button @click.prevent="step = 1; scrollToTop()" class="px-10 mx-3 font-medium py-3 leading-5 text-gray-800 transition-colors duration-200 transform bg-gray-300 rounded-md hover:bg-gray-400 focus:outline-none">Back</button>
                        <button @click.prevent="handleSubmit(nextStep)" class="px-10 font-medium py-3 leading-5 text-white transition-colors duration-200 transform bg-indigo-800 rounded-md hover:bg-indigo-900 focus:outline-none">Next</button>
                     </div>
                  </div>
               </form>
            </ValidationObserver>
         </section>
         <section v-show="step == 3" ref="form-2" class="animate__animated animate__fadeIn ">
            <div class="mt-2 grid grid-cols-2 gap-5 mb-4 mx-8">
               <div class="col-span-2">
                  <h3 class="text-indigo-800 text-2xl font-semibold">Transaction summery</h3>
                  <div class="text-base font-normal py-4">
                  <span class="flex justify-between py-2 items-center border-b border-gray-100">
                    <div class="flex items-center">
                      <h3 class="text-gray-600">
                        Category
                      </h3>
                    </div>
                    <div class="flex items-center">
                      <div class="font-semibold flex flex-shrink-0 justify-center items-center">
                        {{ form.category.name | capitalize }}
                      </div>
                    </div>
                  </span>
                  <span class="flex justify-between py-2 items-center border-b border-gray-100">
                    <div class="flex items-center">
                      <h3 class="text-gray-600">
                        <span v-if="form.category.name != 'Services'">Name of {{ form.category.name }}</span><span v-else>Type of service</span>
                      </h3>
                    </div>
                    <div class="flex items-center">
                      <div class="font-semibold flex flex-shrink-0 justify-center items-center">
                        {{ form.service_name | capitalize }}
                      </div>
                    </div>
                  </span>
                  <span class="flex justify-between py-2 items-center border-b border-gray-100">
                    <div class="flex items-center">
                      <h3 class="text-gray-600">
                        Item price
                      </h3>
                    </div>
                    <div class="flex items-center">
                      <div class="font-semibold flex flex-shrink-0 justify-center items-center">
                        <span v-if="form.currency.name == 'USD'">&#36;</span>
                        <span v-if="form.currency.name == 'NGN'">&#8358;</span>
                        <span v-if="form.currency.name == 'CNY'">&#165;</span>
                        <span v-if="form.currency.name == 'BTC'" class="pr-1">BTC</span> {{ form.price | formatPrice }} 
                      </div>
                    </div>
                  </span>
                  <span class="flex justify-between py-2 items-center border-b border-gray-100">
                    <div class="flex items-center">
                      <h3 class="text-gray-600">
                        Transaction fee
                      </h3>
                    </div>
                    <div class="flex items-center">
                      <div class="font-semibold flex flex-shrink-0 justify-center items-center">
                        <span v-if="form.currency.name == 'USD'">&#36;</span>
                        <span v-if="form.currency.name == 'NGN'">&#8358;</span>
                        <span v-if="form.currency.name == 'CNY'">&#165;</span>
                        <span v-if="form.currency.name == 'BTC'" class="pr-1">BTC</span> {{ escrowFee(form.price) | formatPrice }} 
                      </div>
                    </div>
                  </span>
                  <span class="flex justify-between py-2 items-center border-b border-gray-100">
                    <div class="flex items-center">
                      <h3 class="text-gray-600">
                        Estimated completion date
                      </h3>
                    </div>
                    <div class="flex items-center">
                      <div class="font-semibold flex flex-shrink-0 justify-center items-center">
                        {{ form.duration | formatDate}}
                      </div>
                    </div>
                  </span>
                  <div class="border-b border-gray-100 py-2 ">
                     <div class="justify-start">
                        <h3 class="text-gray-600">
                           Product description
                        </h3>
                     </div>
                     <div class="mt-2">
                        <div class="font-semibold break-words">
                           {{ form.description }}
                        </div>
                     </div>
                  </div>
                  <span class="flex justify-between py-2 items-center border-b border-gray-100">
                    <div class="flex items-center">
                      <h3 class="text-gray-600">
                        Buyer's name
                      </h3>
                    </div>
                    <div class="flex items-center">
                      <div class="font-semibold flex flex-shrink-0 justify-center items-center">
                        {{ form.buyer_name | capitalize }}
                      </div>
                    </div>
                  </span>
                  <span class="flex justify-between py-2 items-center border-b border-gray-100">
                    <div class="flex items-center">
                      <h3 class="text-gray-600">
                        Buyer's phone number
                      </h3>
                    </div>
                    <div class="flex items-center">
                      <div class="font-semibold flex flex-shrink-0 justify-center items-center">
                        {{ form.buyer_phone }}
                      </div>
                    </div>
                  </span>
                  <span class="flex justify-between py-2 items-center border-b border-gray-100">
                    <div class="flex items-center">
                      <h3 class="text-gray-600">
                        Buyer's email address
                      </h3>
                    </div>
                    <div class="flex items-center">
                      <div class="font-semibold flex flex-shrink-0 justify-center items-center">
                        {{ form.buyer_email }}
                      </div>
                    </div>
                  </span>
                  <span class="flex justify-between py-2 items-center border-b border-gray-100">
                    <div class="flex items-center">
                      <h3 class="text-gray-600">
                        Seller's name
                      </h3>
                    </div>
                    <div class="flex items-center">
                      <div class="font-semibold flex flex-shrink-0 justify-center items-center">
                        {{ form.seller_name | capitalize }}
                      </div>
                    </div>
                  </span>
                  <span class="flex justify-between py-2 items-center border-b border-gray-100">
                    <div class="flex items-center">
                      <h3 class="text-gray-600">
                        Seller's phone number
                      </h3>
                    </div>
                    <div class="flex items-center">
                      <div class="font-semibold flex flex-shrink-0 justify-center items-center">
                        {{ form.seller_phone }}
                      </div>
                    </div>
                  </span>
                  <span class="flex justify-between py-2 items-center border-b border-gray-100">
                    <div class="flex items-center">
                      <h3 class="text-gray-600">
                        Seller's email address
                      </h3>
                    </div>
                    <div class="flex items-center">
                      <div class="font-semibold flex flex-shrink-0 justify-center items-center">
                        {{ form.seller_email }}
                      </div>
                    </div>
                  </span>
                </div>
               </div>
               <form class="col-span-2 text-2xl my-5" action="">
                  <div class="flex items-center flex-shrink-0">
                     <input id="accept_terms" type="checkbox" class="rounded mr-2 cursor-pointer" v-model="form.accept_terms" />
                     <label for="accept_terms"
                        class="font-semibold text-gray-500 pt-1 text-sm cursor-pointer"
                        >By clicking on proceed, you agree to Escrowlock's <router-link to="/terms-and-conditions" class="text-indigo-900 underline">Terms of Service</router-link> and <router-link to="/privacy-policy" class="text-indigo-900 underline">Privacy Policy</router-link></label
                     >
                  </div>
               </form>
               <div class="col-span-2">
                  <div class="flex justify-end">
                     <button @click.prevent="previousStep()" class="px-10 mx-3 font-medium py-3 leading-5 text-gray-800 transition-colors duration-200 transform bg-gray-300 rounded-md hover:bg-gray-400 focus:outline-none">Back</button>
                     <button :class="form.accept_terms == false ? 'bg-indigo-300 hover:bg-indigo-400 cursor-not-allowed' : 'bg-indigo-900 hover:bg-indigo-900'" 
                        @click.prevent="form.accept_terms != false ? proceed() : null" class="px-10 font-medium py-3 leading-5 text-white transition-colors duration-200 transform rounded-md focus:outline-none">Complete Transaction</button>
                  </div>
               </div>
            </div>
         </section>
         <section v-show="step == 4" ref="form-2">
            <form class="mt-2 grid grid-cols-1 gap-5 mb-4">
               <div class="col-span-2 h-96 mt-1/2 flex items-center justify-center" v-if="loading">
                  <MazLoader />
                  <!-- During loading time we check if user is registered -->
               </div>
               <div class="text-center py-10" v-else>
                  <template v-if="isRegistered">
                     <div class="text-3xl font-medium text-green-500">Success!</div>
                     <div class="text-xl my-2 font-medium">Transaction Created Successfully!</div>
                     <div class="my-20">
                        <div class="text-gray-500">Your transaction has been created, waiting for both parties to agree. You can share the transaction link with the <span v-if="form.role == 'buyer'">Seller</span> <span v-else>Buyer</span>.</div>
                     </div>
                     <router-link to="/account/user/transactions" class="px-10 font-medium py-3 leading-5 text-white transition-colors duration-200 transform bg-green-500 border border-green-700 rounded hover:bg-green-600 focus:outline-none">View in transactions</router-link>   
                  </template>
                  <template v-else>
                     <div class="text-3xl font-medium text-yellow-300">Pending!</div>
                     <div class="my-20">
                        <div class="text-gray-500">Your transaction is pending because you are not registered, please click on the onboarding link sent to <span class="text-indigo-900 font-semibold">{{ form.buyer_email }}</span> to complete your onboarding process.</div>
                     </div>
                     <!--div @click="cancelTransaction" class="px-10 font-medium py-3 leading-5 text-white transition-colors duration-200 transform bg-indigo-900 border border-gray-900 rounded hover:bg-indigo-800 focus:outline-none">Start New Transaction</div-->   
                  </template>
               </div>
            </form>
         </section>
      </div>
   </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'
import OgCustomSelectWithImage from '@/components/og-custom-select-with-image.vue'
import OgCustomSelect from '@/components/og-custom-select.vue'
import scrollToTop from '@/mixins/scrollToTop.js'

export default {
   name: "BuyerTransaction",
   mixins: [scrollToTop],
   
   components: {
      OgCustomSelectWithImage,
      OgCustomSelect
   },
   
   props: {
      role: {
         type: String,
         required: true
      }
   },

   data() {
      return {
         form: {
            role: this.role,
            currency: { id: 1, name: "NGN", value: 'ngn', logo: 'nigeria.png' },
            category: { id: 1, name: "Select transaction categories", value: '' },
            accept_terms: false,
            milestone: [],
            profile: '',
            buyer_name: '',
            buyer_email: '',
            buyer_phone: ''
         },
         step: 1,
         currencies: [
            { id: 1, name: "USD", value: 'usd', logo: 'united-states.png' },
            { id: 2, name: "NGN", value: 'ngn', logo: 'nigeria.png' },
            { id: 3, name: "CNY", value: 'cny', logo: 'china.png' },
            { id: 4, name: "BTC", value: 'btc', logo: 'bitcoin.png' },
         ],
         categories: [
            { id: 1, name: "Physical product", value: 'Physical product' },
            { id: 2, name: "Services", value: 'Services' },
            { id: 3, name: "Digital product", value: 'Digital product' },
         ],
         isOptionOpen: false,
         checkin: '',
         imageFieldCount: 1,
         url: process.env.VUE_APP_API_URL,
         headers: { 'Escrowlock': '' },
         pickerFormatted: null,
         today: new Date(),
         loading: false,
         isRegistered: false,
         isLoggedIn: false,
         isProfile: 'personal' // check if creator selected personal or business
      }
   }, 

   methods: {
      nextStep() {
         this.step++
         this.scrollToTop()
         // get current
         let getTransData = this.getTransactionData()
         getTransData.step = this.step
         getTransData.formData = this.form
         window.localStorage.setItem('transaction-role', JSON.stringify(getTransData))

         return
      },

      previousStep() {
         this.step--
         this.scrollToTop()
         // get current
         let getTransData = this.getTransactionData()
         getTransData.step = this.step
         getTransData.formData = this.form
         window.localStorage.setItem('transaction-role', JSON.stringify(getTransData))
      },

      escrowFee(amount){
         let fee = null
         amount = parseInt(amount)
         if(amount <= 2000000) {
            fee = amount * 0.0325 // 3.25%
         } else if(amount > 2000000 && amount <= 10000000 ) {
            fee = amount * 0.0075 // 0.75%
         } else if(amount > 10000000) {
            fee = amount * 0.0125
         }

         return fee
      },

      onCurrencySelect(value) {
         this.form.currency = value
      },

      onServiceSelect(value) {
         this.form.category = value
      },

      async proceed() {
         
         this.nextStep()
         this.loading = true

         // check if user is registered
         try{
            const query_params = {
               email: this.form.buyer_email
            }

            let res = await this.$store.dispatch('transactions/checkisregistered', query_params)
            if(res.message == true) {
               this.isRegistered = true
               if(!this.isLoggedIn) {
                  this.$router.push('/auth/sign-in')
               } else {
                  this.processBuyerTransaction()
               }
            } else {
               this.isRegistered = false
               this.processBuyerTransaction()
            }
         } catch(error) {
            this.isRegistered = false
         }
      },
      
      async processBuyerTransaction() {
         this.form.category = this.form.category.value || null
         this.form.currency = this.form.currency.value || null
      
         try{
            let res = await this.$store.dispatch('transactions/create_transaction', this.form)
            this.$toast.success(res.message)
            window.localStorage.removeItem('transaction-role')
            this.loading = false
         } catch(error) {
            this.$toast.error(error.response.message)
            this.$router.push({ name: 'NewTransaction' })            
         }
      },
      
      getTransactionData() {
         let getRole = window.localStorage.getItem('transaction-role');
         getRole = JSON.parse(getRole)

         return getRole
      },

      cancelTransaction() {
         this.form.role = ''
         this.role = ''
         window.localStorage.removeItem("transaction-role")
         this.scrollToTop()
      },

      addMilestone() {
         this.form.milestone.push({title: '', description: '', due_date: '', amount_to_release: ''})
         this.milestoneCount++
      },

      removeMilestone(index) {
         this.form.milestone.splice(index, 1)
      },

      checkMilestone() {
         
         //let arr = []
         let a = Object.values(this.form.milestone)
         a = a.map(({amount_to_release}) => ({amount_to_release}))
         a = a.map(({ amount_to_release }) => amount_to_release)
         a = a.reduce((x,y) => parseInt(x) + parseInt(y), 0)

         if(this.form.category.name == 'Services') {
            if(a > parseInt(this.form.price)) {
               this.$toast.error("Total milestone amounts cannot be higher than the original service price")
            } else {
               this.nextStep()
               return
            }
         } else {
            this.nextStep()
            return
         }
      }
   },

   directives: {
      ClickOutside
   },

   mounted() {
      let getTransData = this.getTransactionData()
      if(getTransData.step != '') {
         this.step = getTransData.step
      }
      if(getTransData.formData != null) {
         this.form = getTransData.formData
      }
      if(this.loading == false && getTransData.step == 4) {
         getTransData.step = 3
         window.localStorage.setItem('transaction-role', JSON.stringify(getTransData))
         this.step = 3
      }
      if(this.$store.state.auth.token != null) {
         this.isLoggedIn = true
      }
   }
}
</script>

<style scoped>

</style>